import React from 'react'
import {graphql, Link} from 'gatsby'

import Layout from '../components/layout'
import Nav from '../components/nav'

// @ts-ignore
const PostPageTemplate = ({data: {contentfulPost}}) => {

    let postedCount 

    return (
        <Layout>
            <Nav/>
            <h3>{contentfulPost.title}</h3>
            <div>{contentfulPost.postDate}</div>
            <div>Category: <Link to={`/category/${contentfulPost.category.contentId}`}>
              {contentfulPost.category.title}</Link></div>
              {contentfulPost.postBody && 
                <div dangerouslySetInnerHTML={{__html: contentfulPost.postBody.childMarkdownRemark.html}} />
              }
        </Layout>
    )
}

export const query = graphql`
query ($slug: String) {
    contentfulPost(slug: {eq: $slug}) {
      id
      title
      slug
      postDate (formatString: "MMMM DD, YYYY")
      postBody {
        childMarkdownRemark {
          html
        }
      }
      category {
        slug
        title
      }
    }
}
`

export default PostPageTemplate;